@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    @apply text-[#212529] font-poppins h-[100%];
  }

  body {
    @apply h-[100%] flex flex-col;
  }

  [type='text'],
  [type='email'],
  [type='url'],
  [type='password'],
  [type='number'],
  [type='date'],
  [type='datetime-local'],
  [type='month'],
  [type='search'],
  [type='tel'],
  [type='time'],
  [type='week'],
  [multiple],
  textarea,
  select {
    @apply w-full rounded-lg py-[10px] px-4 text-fs-9 text-neutral-700 font-medium bg-white border-neutral-300 border ring-0 transition duration-200 focus:border-neutral-400 focus:ring-0 placeholder:text-neutral-400 placeholder:font-normal placeholder:text-fs-9 placeholder:tracking-tight disabled:bg-neutral-100;
  }

  [type='checkbox'] {
    @apply transition focus:ring-0 focus:ring-offset-0 w-5 h-5 rounded bg-white border-neutral-300 cursor-pointer text-primary-500;
  }

  [type='radio'] {
    @apply transition focus:ring-0 focus:ring-offset-0 bg-white w-6 h-6 border-neutral-300 text-primary-500;
  }
}

input:checked ~ .dot {
  transform: translateX(100%);
  right: 2px;
}

input:checked ~ .backside {
  background-color: #0085e5 !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Custom Scrollbar */
/* Track */
.custom-scrollbar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #212529;
  border-radius: 8px;
}

/* Handle */
.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #343a40;
  border-radius: 10px;
}

/* Handle on hover */
.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #212529;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 10px;
}

/* tinymce hard style  */
.tox-tinymce {
  border-radius: 8px !important;
  border: 1px solid #f5f7f9 !important;
}

.tox .tox-edit-area__iframe {
  background-color: #f5f7f9 !important;
}

.tox-statusbar {
  display: none !important;
}

.tox-toolbar__primary {
  background: none !important;
}

.tox .tox-editor-header {
  z-index: 0 !important;
}

@layer components {
  .custom-calendar .rmdp-wrapper {
    border: 1px solid #000000;
    box-shadow: 0 0 5px #000000;
  }

  .custom-calendar .rmdp-panel-body li {
    background-color: #000000;
    box-shadow: 0 0 2px #000000;
  }

  .custom-calendar .rmdp-week-day {
    @apply text-[#212529] font-semibold;
  }

  .custom-calendar .rmdp-day.rmdp-deactive {
    @apply hover:bg-[#f1f3f5] text-[#e9ecef] rounded;
  }

  .custom-calendar .rmdp-range {
    background-color: #e3fafc;
    box-shadow: 0 0 3px #e3fafc;
    color: #003768;
  }

  .custom-calendar .rmdp-range:hover {
    background-color: #e3fafc;
    box-shadow: 0 0 3px #e3fafc;
    color: #003768;
  }

  .custom-calendar .rmdp-range.start,
  .custom-calendar .rmdp-range.end {
    background-color: #00487a;
    box-shadow: 0 0 3px #00487a;
    color: #ffffff;
    border-radius: 50%;
  }

  .custom-calendar .rmdp-arrow {
    border: solid #000000;
    border-width: 0 2px 2px 0;
  }

  .custom-calendar .rmdp-arrow-container:hover {
    background-color: #000000;
    box-shadow: 0 0 3px #000000;
  }

  .custom-calendar .rmdp-panel-body::-webkit-scrollbar-thumb {
    background: #000000 !important;
  }

  .custom-calendar .rmdp-day.rmdp-today span {
    @apply bg-neutral-100 text-neutral-600 rounded-full;
  }

  .custom-calendar .rmdp-rtl .rmdp-panel {
    border-left: unset;
    border-right: 1px solid #000000;
  }

  .custom-calendar .rmdp-day.rmdp-selected span:not(.highlight) {
    @apply bg-blue-600 text-white rounded;
  }

  .custom-calendar .rmdp-day:not(.rmdp-day-hidden) span:hover {
    @apply hover:bg-neutral-100 hover:text-neutral-600 rounded-full;
  }

  .custom-calendar .b-deselect {
    color: #000000;
    background-color: white;
  }

  .custom-calendar .rmdp-action-button {
    color: #000000;
  }

  .custom-calendar .rmdp-button:not(.rmdp-action-button) {
    background-color: #000000;
  }

  .custom-calendar .rmdp-button:not(.rmdp-action-button):hover {
    background-color: #000000;
  }

  .custom-calendar .rmdp-time-picker input {
    width: 40px;
  }

  #range-portal-div,
  #toast-portal {
    z-index: 99999;
  }
}

.splash-screen {
  position: fixed;
  z-index: 1000;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #f2f3f8;
}

.splash-screen img {
  margin-bottom: 30px;
}

.tooltip-custom {
  padding: 2px 6px !important;
  font-size: 0.625rem !important;
  line-height: 16px !important;
  letter-spacing: 0.4px !important;
  z-index: 10000;
}

.tooltip-client-management {
  padding: 8px !important;
  font-size: 12px !important;
  line-height: 16px !important;
  letter-spacing: 0.4px !important;
  border-radius: 8px !important;
  z-index: 10000;
}

.tooltip-descName {
  background-color: white !important;
  color: #212529 !important;
  padding: 1rem !important;
  width: 270px !important;
  font-weight: 500 !important;
  font-size: 0.875rem !important;
  line-height: 20px !important;
  letter-spacing: 0.25px !important;
  border: 1px solid #e9ecef;
  border-radius: 16px;
}

.tooltip-descName-arrow {
  border: 1px solid #dee2e6;
}

.faq-line-clamp {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
}

.parent-container:focus-within .prefix-element {
  border-color: #98a2b3 !important;
  transition-duration: 300ms;
  transition-property: border-color;
}

.overflow-ellips-2 {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 2 !important;
  -webkit-box-orient: vertical !important;
}

.grecaptcha-badge {
  display: none !important;
}

.pincode-input-text::placeholder {
  font-size: 32px;
  font-weight: 500;
}

@layer components {
  .custom-job-calendar .rmdp-wrapper {
    border: 1px solid #000000;
    box-shadow: 0 0 5px #000000;
  }

  .custom-job-calendar .rmdp-week-day {
    @apply text-neutral-600 font-semibold hover:bg-danger-300;
  }
  .custom-job-calendar .rmdp-day {
    @apply w-9 h-9;
  }
  .custom-job-calendar .rmdp-day.rmdp-today span {
    @apply bg-primary-500 text-white;
  }
  .custom-job-calendar .rmdp-day.rmdp-today.rmdp-selected span:not(.highlight) {
    @apply bg-primary-500 text-white;
  }
  .custom-job-calendar
    .rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden).rmdp-today.rmdp-selected
    span:hover {
    @apply bg-primary-500 text-white;
  }
  .custom-job-calendar
    .rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden):not(.rmdp-selected).rmdp-today
    span:hover {
    @apply bg-primary-500 text-white;
  }

  .custom-job-calendar .rmdp-day.rmdp-selected span:not(.highlight) {
    @apply bg-primary-100 text-neutral-600 border-primary-100 shadow-none;
  }
  .custom-job-calendar
    .rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden):not(.rmdp-selected)
    span:hover {
    @apply bg-neutral-100 text-neutral-600;
  }
  .custom-job-calendar .rmdp-header .rmdp-header-values {
    @apply m-0 w-full text-left;
  }

  .custom-job-calendar .rmdp-week-day:hover {
    @apply bg-white;
  }
}

.resizer {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 1px;
  cursor: col-resize;
  user-select: none;
  touch-action: none;
}

.resizer.isResizing {
  background: rgba(0, 0, 0, 0.5);
  opacity: 1;
}

@media (hover: hover) {
  .resizer {
    opacity: 0;
  }

  *:hover > .resizer {
    opacity: 1;
  }
}
